import React from 'react';
import usePinterestAuth from '../../Api/usePinterestAuth';
import AuthResponse from '../../Components/AuthResponse/Index';
import { useAuth } from '../../Hooks/useAuth';



const PinterestAuth = () => {

    const pinterestAuth = usePinterestAuth();
    const [response, setResponse] = React.useState('')
    // eslint-disable-next-line no-unused-vars
    const [status, setStatus] = React.useState(false)
 



    const client_id = ""
    const client_secret =""



    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchParams = new URLSearchParams(urlObj.search);
    const authorization_code = searchParams.get("code");

    React.useEffect(() => {
        async function getAccessToken() {
            const pfs = await pinterestAuth.getAccessToken(authorization_code, client_id, client_secret )
                console.log(pfs)

                if (pfs.success) {
                    setResponse(pfs.access_token);
                    pinterestAuth.storeAccessToken(`${pfs.access_token}`);
                } else {
                    setResponse(`${pfs.error}`);
                    console.log('no token was saved')
                }
        
    }
        getAccessToken();

    }, [])

    return (
        <>
            <AuthResponse response={response} status={status} />
        </>


    );
}

export default PinterestAuth;