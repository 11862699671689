import { n2api } from "./n2api";

const useCreateCampaign = () => {

    const postCampaign = async (campaign, platforms, token) => {


        const creationPromises = platforms.map(async platform => {
            const creationResponse = await n2api.post(
                `/${platform}/campaigns`,
                campaign,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 302) {
                    if (response.data) {
                        return {
                            success: true,
                            data: response.data,
                            error: null
                        }

                    } else {
                        return {
                            success: false,
                            data: response,
                            error: new Error('No campaign found')
                        }
                    }
                } else {
                    return {
                        success: false,
                        data: response,
                        error: new Error(response.statusText)
                    }
                }
            })
            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    data: {},
                    error: error
                }
            });
            return creationResponse;
        })
        return await Promise.all(creationPromises);
    };
    return {
        postCampaign,
    }
}

export default useCreateCampaign;
