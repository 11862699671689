import { n2api } from './n2api';

const usePlatforms = () => {

  const getPlatforms = (token) => {
    return n2api.get('/platform', {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data);
  }

  const getPlatform = (name, token) => {
    return n2api.get(`/platform/${name}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data);
  }

  return {
    getPlatforms,
    getPlatform,
  };
}

export default usePlatforms;