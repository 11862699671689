import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from '../../Hooks/useAuth'


const teams = [
  // { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  // { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  // { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SidebarLayout({navigation}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is not defined and navigate to /login
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);


  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/70" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black1 px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={"/logo.svg"}
                        alt="n_2 logo"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  activeClassName="bg-white text-gray-600"

                                  className={({isActive}) => classNames(
                                    isActive
                                      ? 'bg-white text-gray-600 '
                                      : 'text-gray-700 hover:text-gray-600 hover:bg-gray-200 ',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )
                                  }
                                >
                                  <item.icon
                                    className={
                                      'h-6 w-6 shrink-0'
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {
                          teams.length > 0 &&
                          <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                            <ul className="-mx-2 mt-2 space-y-1">
                              {teams.map((team) => (
                                <li key={team.name}>
                                  <a
                                    href={team.href}
                                    className={classNames(
                                      team.current
                                        ? 'bg-gray-50 text-emerald-600'
                                        : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        team.current
                                          ? 'text-emerald-600 border-emerald-600'
                                          : 'text-gray-400 border-gray-200 group-hover:border-emerald-600 group-hover:text-emerald-600',
                                        'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                      )}
                                    >
                                      {team.initial}
                                    </span>
                                    <span className="truncate">{team.name}</span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        }
                      </ul>
                      <button
                        onClick={() => {
                          console.log('logging out')
                          logout()
                        }}
                        className={`hover:bg-opacity-70 bg-gray-800 text-center text-white0 font-semibold flex items-center py-2 px-9 rounded`}>
                        <p className='text-center'>Log out</p>
                      </button>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray2 bg-black1 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src={"/logo.svg"}
                alt="n_2 logo"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={({isActive}) =>
                            classNames(
                            isActive
                              ? 'bg-green text-black0 '
                              : 'text-white0 hover:text-black0 hover:bg-green hover:bg-opacity-70 ',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )
                          }
                        >
                          <item.icon
                            className={classNames(
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>

                {
                  teams.length > 0 &&
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                    <ul className="-mx-2 mt-2 space-y-1">
                      {teams.map((team) => (
                        <li key={team.name}>
                          <a
                            href={team.href}
                            className={classNames(
                              team.current
                                ? 'bg-gray-50 text-emerald-600'
                                : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                          >
                            <span
                              className={classNames(
                                team.current
                                  ? 'text-emerald-600 border-emerald-600'
                                  : 'text-gray-400 border-gray-200 group-hover:border-emerald-600 group-hover:text-emerald-600',
                                'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                              )}
                            >
                              {team.initial}
                            </span>
                            <span className="truncate">{team.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                }
                <li className="-mx-6 mt-auto">
                  <div className="px-6 py-3 text-sm font-semibold leading-6 text-gray-400">
                    <a
                      href="/user"
                      className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-400"
                    >
                      <img
                        className="h-8 w-8 rounded-full bg-gray-50"
                        src={`https://api.dicebear.com/7.x/initials/svg?seed=${user.email}&backgroundColor=b6e3f4,7cb342,43a047,00897b,c0ca33`}
                        alt=""
                      />
                      <span className="sr-only">Your profile</span>
                      <span aria-hidden="true">{user.email}</span>
                    </a>
                    <button
                    onClick={() => {
                      console.log('logging out')
                      logout()
                    }}
                    className={`hover:bg-opacity-70 bg-gray-800 text-white0 font-semibold flex items-center py-2 px-9 rounded`}>
                      <p>Log out</p>
                    </button>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-black1 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6 text-emerald-500" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-500">Dashboard</div>
          <a href="/user">
            <span className="sr-only">Your profile</span>
            <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src={`https://api.dicebear.com/7.x/initials/svg?seed=${user.emailmail}&backgroundColor=b6e3f4,7cb342,43a047,00897b,c0ca33`}
              alt=""
            />
          </a>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  )
}
