export default function ActiveAgentLogo({className}) {
  return <div className={`w-6 h-6 rounded-full ${className??""}`}>

    <svg viewBox="0 0 155 155" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M130.3 0H24.1C10.8 0 0 10.8 0 24V130.2C0 143.4 10.8 154.3 24.1 154.4H130.3C143.6 154.4 154.4 143.5 154.4 130.3V24.1C154.4 10.8 143.5 0 130.3 0Z" fill="url(#paint0_linear_7_149)" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9 98.9C26.5 96.4 26.6 93.5 28.2 91L69 27.2C70.5 24.9 72.8 23.6 75.5 23.6C78.2 23.6 80.6 24.9 82 27.2L88.5 37.4C90.1 40 90.1 43.1 88.5 45.7L54.2 99.3C52.7 101.6 50.4 102.9 47.7 102.9H34.7C31.8 102.9 29.3 101.4 27.9 98.9Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M67.7 130.5H54.7C51.8 130.5 49.3 129 47.9 126.5C46.5 124 46.6 121.1 48.2 118.6L89 54.7C90.5 52.4 92.8 51.1 95.5 51.1C98.2 51.1 100.6 52.4 102 54.7L108.5 64.9C110.2 67.5 110.2 70.7 108.5 73.3L74.2 126.9C72.7 129.2 70.4 130.5 67.7 130.5Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M126.9 101C126.2 102.2 125 102.9 123.6 102.9H102.4C101 102.9 99.8 102.2 99.1 101C98.4 99.8 98.5 98.4 99.2 97.2L109.8 80.6C110.5 79.5 111.6 78.9 112.9 78.9C114.3 78.9 115.5 79.5 116.2 80.6L126.8 97.2C127.5 98.4 127.6 99.8 126.9 101Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_7_149" x1="-49.2079" y1="216.349" x2="176.468" y2="-32.122" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1F5B73" />
          <stop offset="0.1811" stop-color="#256775" />
          <stop offset="0.5114" stop-color="#35887A" />
          <stop offset="0.9509" stop-color="#4FBD82" />
          <stop offset="1" stop-color="#52C383" />
        </linearGradient>
      </defs>
    </svg>

  </div>
}