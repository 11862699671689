import { useState } from "react";

/*
  This hook is used to store and retrieve values from local storage.
  It takes two arguments:
  - keyName: the name of the key to be used in local storage
  - defaultValue: the default value to be used if the key is not found in local storage
  It returns an array with two elements:
  - storedValue: the value stored in local storage
  - setValue: a function to update the value stored in local storage
*/
export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.log(err);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};