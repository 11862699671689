import ActiveAgentConnectButton from "./ActiveAgentConnectButton";
import FacebookConnectButton from "./FacebookConnectButton";
import GoogleConnectButton from "./GoogleConnectButton";
import LinkedInConnectButton from "./LinkedInConnectButtons";
import NotImplementedConnectButton from "./NotImplementedConnectButton";

export default class PlatformConnectButtonFactory {

  static create(platformName) {
    switch(platformName) {
      case 'Facebook':
        return <FacebookConnectButton />;
      case 'Google':
        return <GoogleConnectButton />;
      case 'LinkedIn':
        return <LinkedInConnectButton />;
      case 'ActiveAgent':
        return <ActiveAgentConnectButton />;
      default:
        return <NotImplementedConnectButton />;
    }
  }
}