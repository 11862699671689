import React, { useEffect, useState } from 'react';
import useFacebookAuth from '../../Api/useFacebookAuth';
import FacebookLogo from '../PlatformLogos/FacebookLogo';
import { useAuth } from '../../Hooks/useAuth';

export default function FacebookConnectButton() {
  const facebookAuth = useFacebookAuth();

  const facebook_client_id = process.env.REACT_APP_FACEBOOK_CLIENT_ID
  const facebook_redirect_uri = process.env.REACT_APP_FACEBOOK_REDIRECT_URI
  const apiVersion = process.env.REACT_APP_FACEBOOK_API_VERSION

  const [facebookConnected, setFacebookConnected] = useState(false)
  const [facebookIsDisconnecting, setFacebookIsDisconnecting] = useState(false)
  const scopes = [
    'ads_management',
    'ads_read',
    'pages_read_engagement',
  ]

  useEffect(()=> {
    facebookAuth.isConnected().then(
      isConnected => setFacebookConnected(isConnected)
    )
  }, []) 

  return (
    <div className="-ml-px">
    {
      !facebookConnected?
      <div>
      <div id="fb-root"></div>
      <a
      href={`https://www.facebook.com/${apiVersion}/dialog/oauth?client_id=${facebook_client_id}&redirect_uri=${facebook_redirect_uri}&scope=${scopes.join(',')}`}
      
      >
      <button
      style={{backgroundColor: '#1977F3'}}
      className={`hover:bg-opacity-70 text-white0 font-semibold flex items-center py-2 px-9 rounded`}><FacebookLogo className="h-6 w-6 mr-2" alt="Facebook Icon"/>
      <p>Continue with Facebook</p>
      </button>
      </a>
      </div>
      :
      <div>
      
      <div id="fb-root"></div>
      
      <button
      disabled={facebookIsDisconnecting}
      onClick={()=> {
        setFacebookIsDisconnecting(true)
        facebookAuth.disconnectFacebook().then(
          response => {
            setFacebookConnected(!response?.success);
            setFacebookIsDisconnecting(false);
          }
          )}
        }
        className={
          `hover:bg-opacity-70 bg-facebookblue text-white0 font-semibold flex items-center py-2 px-9 rounded`
        }><FacebookLogo className={`h-6 w-6 mr-2 ${
          facebookIsDisconnecting? 'animate-spin' : ''
        }`} alt="Facebook Icon"/>
        <p>Disconnect{facebookIsDisconnecting ? 'ing' : ''} from Facebook{facebookIsDisconnecting ? '...' : ''}</p>
        </button> 
        </div>
    }
    </div>
  )
}