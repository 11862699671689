import React, { useState } from "react";
import useCreateCampaign from '../../Api/useCreateCampaign';
import PostCampaignResponse from './PostCampaignResponse'
import { useAuth } from "../../Hooks/useAuth";


export default function CreateCampaignComponent() {

    const [KPIOptimizationChecked, setKPIOptimizationChecked] = useState(false)
    const [campaignName, setCampaignName] = useState("")
    const [status, setStatus] = useState("pause")
    const [settings, setSettings] = useState([])
    const [platforms, setPlatforms] = useState([])
    const create = useCreateCampaign();
    const [creationSuccessful, setCreationSuccessful] = useState(false);
    const [createdCampaigns, setCreatedCampaigns] = useState([])
    const { token } = useAuth();


    const campaignTemplate = {

        name: campaignName,
        status: status,
        settings: settings,
    }

    async function createCampaign(campaign, campaignPlatforms) {
        const createdCampaign = await create.postCampaign(campaign, campaignPlatforms, token);


        console.log("Created campaign:", createdCampaign)
        if (createdCampaign.some(campaign => campaign.error === null)) {
            setCreatedCampaigns(createdCampaign);
            setCreationSuccessful(true);
        }

    }

    return (
        <>
            {creationSuccessful ?
                <div>
                    {
                        createdCampaigns.map(campaign => {
                            return <PostCampaignResponse error={campaign.error} data={campaign.data} />
                        })
                    }
                </div>
                :
                <div class="bg-gray2 rounded-lg px-8 py-6 mx-auto my-8 max-w-2xl">
                    <h2 class="text-2xl font-medium mb-4 text-white1">Create Campaign</h2>
                    <div>
                        <div class="mb-4">
                            <label for="name" class="block text-white0 font-medium mb-2">Name of the campaign</label>
                            <input type="text" id="name" name="name"
                                class="border border-gray2 p-2 w-full rounded-lg focus:outline-none focus:border-green"
                                onInput={
                                    e => setCampaignName(e.target.value)
                                }
                                required />
                        </div>
                        <div class="mb-4">
                            <label for="status" class="block text-white0 font-medium mb-2">Status</label>
                            <select id="status" name="status"
                                class="border border-gray2 p-2 w-full rounded-lg focus:outline-none focus:border-green text-gray2"
                                onChange={
                                    e => setStatus(e.target.value)
                                }
                                required>
                                <option value="">Select status</option>
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="PAUSED">PAUSED</option>
                            </select>
                        </div>
                        <div class="mb-4">
                            <label class="block text-white0 font-medium mb-2">Settings</label>
                            <div class="flex flex-wrap -mx-2">
                                <div class="px-2 w-1/3">
                                    <label for="setting-kpi" class="block text-white0 font-medium mb-2">
                                        <input type="checkbox" id="setting-kpi" name="setting" value="KPIOptimization" class="accent-green mr-2"
                                            onChange={
                                                e => {
                                                    setKPIOptimizationChecked(e.target.checked);

                                                }

                                            }
                                        />
                                        KPIOptimization
                                    </label>
                                </div>

                            </div>
                        </div>

                        {
                            KPIOptimizationChecked ?
                                <div class="mb-4">
                                    <label for="OptimziationType" class="block text-white0 font-medium mb-2">OptimizationType</label>
                                    <select id="OptimizationType" name="OptimizationType"
                                        class="border border-gray2 p-2 w-full rounded-lg focus:outline-none focus:border-green text-gray2"
                                        onChange={
                                            e => {
                                                let stgs = [...settings]
                                                // delete old KPIsetting
                                                stgs = stgs.filter(setting => setting.settingType !== "KPIOptimization")
                                                const KPISetting = {
                                                    settingType: "KPIOptimization",
                                                    kpiOptimization: e.target.value
                                                };
                                                stgs.push(KPISetting)
                                                setSettings(stgs);
                                                console.log(stgs)
                                            }

                                        }
                                        required>
                                        <option value="">Select OptimizationType</option>
                                        <option value="Unspecified">Unspecified</option>
                                        <option value="CostPerClick">Cost Per Click</option>
                                        <option value="CostPerLead">Cost Per Lead</option>
                                        <option value="CostPerOrder">Cost Per Order</option>
                                        <option value="ViewThroughRate">View Through Rate</option>
                                        <option value="ClickThroughRate">Click Through Rate</option>
                                        <option value="CostPerView">Cost Per View</option>
                                        <option value="CostPerCompletedView">Cost Per Completed View</option>
                                        <option value="Reach">Reach</option>
                                        <option value="ReturnOnAdSpend">Return On Ad Spend</option>
                                        <option value="Unknown">Unknown</option>
                                        <option value="Impressions">Impressions</option>
                                        <option value="Clicks">Clicks</option>
                                    </select>
                                </div>
                                :
                                null
                        }

                        <div class="mb-4">
                            <label class="block text-white0 font-medium mb-2">Platform</label>
                            <div class="flex flex-wrap -mx-2">
                                <div class="px-2 w-1/3" >
                                    <label for="platform" class="block text-white0 font-medium mb-2" >
                                        <input type="checkbox" id="platform-facebook" name="platform" value="facebook" class="accent-green mr-2"
                                            onChange={
                                                e => {
                                                    let pfs = [...platforms]
                                                    if (e.target.checked) {
                                                        pfs.push(e.target.value)
                                                    } else {
                                                        pfs = pfs.filter(pf => pf !== e.target.value)
                                                    }
                                                    setPlatforms(pfs)
                                                }
                                            }
                                            required />Facebook
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div>
                            <button type="submit" class="bg-green text-white0 px-4 py-2 rounded-lg hover:bg-opacity-70"
                                onClick={
                                    () => createCampaign(campaignTemplate, platforms)
                                }
                            >Create Campaign</button>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}