import CreateCampaignComponent from '../../Components/CreateCampaign/Index';
import React from 'react';

const CreateCampaign = () => {

    return (
        <>
          <CreateCampaignComponent />
        </>
      );
}

export default CreateCampaign;