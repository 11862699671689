import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

/*
  The AuthContext is a React Context that provides the current authentication state and a method to log in.
*/
const AuthContext = createContext();

/*
  The AuthProvider is a React component that provides the AuthContext to its children.
*/
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async ({user, token}) => {
    setUser(user);
    setToken(token);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setToken(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      token,
      login,
      logout,
    }),
    [user, token]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/*
  This is a custom hook that provides access to the AuthContext.
*/
export const useAuth = () => {
  // what this line does: it returns the current value of the AuthContext
  // the value is an object with the user, token, login, and logout properties
  // but only if the useAuth hook is called from a component that is a child of the AuthProvider
  return useContext(AuthContext);
}