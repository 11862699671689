import React from "react";
import {
  XMarkIcon,
} from '@heroicons/react/24/outline'
export default function NotImplementedConnectButton() {
  return (
    <button
      disabled={true}
      className={`hover:bg-opacity-70 bg-gray-700 text-white0 font-semibold flex items-center py-2 px-9 rounded`}>
        <XMarkIcon className="h-6 w-6 mr-2" alt="Facebook Icon"/>
      <p>Coming soon...</p>
    </button>
  );
}