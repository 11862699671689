import React from 'react';
import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import SidebarLayout from './Components/SidebarLayout/Index';
import {
  CircleStackIcon,
  HomeIcon,
  CloudIcon,
} from '@heroicons/react/24/outline'
import Campaigns from './Pages/Campaigns/Index';
import AdSets from './Pages/AdSets/Index';
import Platforms from './Pages/Platforms/Index';
import Login from './Pages/Auth/Login';
import Platform from './Pages/Platform/Index';
import User from './Pages/User/Index';
import GoogleAdsAuth from './Pages/GoogleAdsAuth/Index';
import FacebookAuth from './Pages/FacebookAuth/Index';
import PinterestAuth from './Pages/PinterestAuth/Index';
import CreateCampaign from './Pages/CreateCampaign/createCampaign';
import Dashboard from './Pages/Dashboard/Index';
import { AuthProvider } from './Hooks/useAuth';
import { ProtectedLayout } from './Components/ProtectedRoute/Index';
import LinkedInAuth from './Pages/LinkedInAuth/Index';
import ActiveAgentLogin from './Components/ActiveAgentLogin/Index';



const Router = () => {

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
    { name: 'Platforms', href: '/platforms', icon: CloudIcon, current: false },
    { name: "Campaigns", href: '/campaigns', icon: CircleStackIcon, current: false },
    { name: 'AdSets', href: '/adsets', icon: CircleStackIcon, current: false },
    // { name: 'Reports', href: '/reports', icon: ChartPieIcon, current: false },
  ]

  const routes = 
    <>
      <Route element={<ProtectedLayout />}>
        <Route path="/" element={<SidebarLayout navigation={navigation} />}>
          <Route index={true} element={<Dashboard />} />
          <Route path="team">
            <Route index={true} element={<p>Team</p>} />
          </Route>
          <Route path="campaigns">
            <Route index={true} element={<Campaigns />} />
            <Route path="create">
              <Route index={true} element={<CreateCampaign />} />
            </Route>
          </Route>
          <Route path="adSets">
            <Route index={true} element={<AdSets />} />
          </Route>
          {/* <Route path="reports">
            <Route index={true} element={<p class="md:text-white0">Reports</p>} />
          </Route> */}
          <Route path="platforms">
            <Route index={true} element={<Platforms />} />
            <Route path=":name" element={<Platform />} />
            <Route path="googleads/auth/success" element={<GoogleAdsAuth />} />
            <Route path="facebook/auth/success" element={<FacebookAuth />} />
            <Route path="pinterest/auth/success" element={<PinterestAuth />} />
            <Route path="linkedin/auth/success" element={<LinkedInAuth /> } />
          </Route>
          <Route path="activeagent">
            <Route path="credentials" element={<ActiveAgentLogin /> } />
          </Route>
          <Route path="user">
            <Route index={true} element={<User />} />
          </Route>
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
    </>

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {routes}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;