import ActiveAgentLogo from "./ActiveAgentLogo";
import FacebookLogo from "./FacebookLogo";
import PinterestLogo from "./PinterestLogo";
import SpotifyLogo from "./SpotifyLogo";

export default class PlatformLogosFactory {

  static create(platformName) {
    switch(platformName) {
      case 'Facebook':
        return <FacebookLogo className="w-6 h-6" inverted={true} />;
      case 'Pinterest':
        return <PinterestLogo />;
      case 'ActiveAgent':
        return <ActiveAgentLogo />;
      case 'Spotify':
        return <SpotifyLogo />;
      default:
        return <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={`https://api.dicebear.com/6.x/initials/svg?seed=${platformName}`} alt={platformName} />
    }
  }
}