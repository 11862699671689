import { useAuth } from '../Hooks/useAuth';
import { n2api } from './n2api';
import axios from 'axios';

const useActiveAgentAuth = () => {

  const { token } = useAuth();

  const storeCredentials = (username, password) => {
    const createCredentialDto = {
        username: username,
        password: password
    };

    // Make a POST request to the n2api backend to store the refresh token
    n2api.put(`/activeagent/credentials`, createCredentialDto, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    })
    .then((response) => {
        console.log('Credentials stored successfully');
        // Handle the response as needed
    })
    .catch((error) => {
        console.error('Error storing Credentials:', error);
        // Handle the error
    });
  }

  const getCredentials = async () => {
    return n2api.get(`/activeagent/credentials`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      return {
        success: true,
        data: response.data
      }
    })
    .catch(error => {
      console.log(error);
      return {
        success: false,
        data: {},
        error: error
      }
    });
  }

  const deleteCredentials = async () => {
    return n2api.delete(`/activeagent/credentials`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      return {
        success: true,
        data: response.data
      }
    })
    .catch(error => {
      console.log(error);
      return {
        success: false,
        data: {},
        error: error
      }
    });
  }

  return {
    storeCredentials,
    getCredentials,
    deleteCredentials,
  }
}

export default useActiveAgentAuth;