export default function SpotifyLogo() {
  return <div className="w-6 h-6 rounded-full">
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8_150)">
        <path fill="#1DB954" d="M18 0C8.06371 0 0 8.06371 0 18C0 27.9363 8.06371 36 18 36C27.9363 36 36 27.9363 36 18C36 8.06371 27.9363 0 18 0ZM25.3089 26.4847C25.004 26.4847 24.8153 26.3903 24.5323 26.2234C20.0032 23.4944 14.7339 23.3782 9.52984 24.4452C9.24677 24.5177 8.87661 24.6339 8.66613 24.6339C7.9621 24.6339 7.51935 24.075 7.51935 23.4871C7.51935 22.7395 7.9621 22.3839 8.50645 22.2677C14.4508 20.954 20.5258 21.0702 25.7081 24.1694C26.1508 24.4524 26.4121 24.7065 26.4121 25.3669C26.4121 26.0274 25.8968 26.4847 25.3089 26.4847ZM27.2613 21.7234C26.8839 21.7234 26.6298 21.5565 26.3685 21.4185C21.8323 18.7331 15.0677 17.6516 9.05081 19.2847C8.70242 19.379 8.51371 19.4734 8.1871 19.4734C7.41048 19.4734 6.77903 18.8419 6.77903 18.0653C6.77903 17.2887 7.15645 16.7734 7.90403 16.5629C9.92177 15.9968 11.9831 15.5758 15.0024 15.5758C19.7129 15.5758 24.2637 16.7444 27.8492 18.8782C28.4371 19.2266 28.6694 19.6766 28.6694 20.3081C28.6621 21.0919 28.0524 21.7234 27.2613 21.7234ZM29.5113 16.1927C29.1339 16.1927 28.9016 16.0984 28.575 15.9097C23.4073 12.825 14.1677 12.0847 8.1871 13.754C7.92581 13.8266 7.59919 13.9427 7.25081 13.9427C6.29274 13.9427 5.55968 13.1952 5.55968 12.2298C5.55968 11.2427 6.16935 10.6839 6.82258 10.4952C9.37742 9.74758 12.2371 9.39193 15.3508 9.39193C20.6492 9.39193 26.2016 10.4952 30.2589 12.8613C30.825 13.1879 31.1952 13.6379 31.1952 14.5016C31.1952 15.4887 30.3968 16.1927 29.5113 16.1927Z" />
      </g>
      <defs>
        <clipPath id="clip0_8_150">
          <rect width="36" height="36" fill="#1DB954" />
        </clipPath>
      </defs>
    </svg>
  </div>
}