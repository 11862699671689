import React, { useEffect } from "react";
import LoadingAnimation from "../LoadingAnimation/Index";

const PinterestLogo = () => {
  return <div className="w-6 h-6 rounded-full">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="#E60023" d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" /></svg>
  </div>
}

const ActiveAgentLogo = () => {
  return <div className="w-6 h-6 rounded-full">

    <svg viewBox="0 0 155 155" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M130.3 0H24.1C10.8 0 0 10.8 0 24V130.2C0 143.4 10.8 154.3 24.1 154.4H130.3C143.6 154.4 154.4 143.5 154.4 130.3V24.1C154.4 10.8 143.5 0 130.3 0Z" fill="url(#paint0_linear_7_149)" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9 98.9C26.5 96.4 26.6 93.5 28.2 91L69 27.2C70.5 24.9 72.8 23.6 75.5 23.6C78.2 23.6 80.6 24.9 82 27.2L88.5 37.4C90.1 40 90.1 43.1 88.5 45.7L54.2 99.3C52.7 101.6 50.4 102.9 47.7 102.9H34.7C31.8 102.9 29.3 101.4 27.9 98.9Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M67.7 130.5H54.7C51.8 130.5 49.3 129 47.9 126.5C46.5 124 46.6 121.1 48.2 118.6L89 54.7C90.5 52.4 92.8 51.1 95.5 51.1C98.2 51.1 100.6 52.4 102 54.7L108.5 64.9C110.2 67.5 110.2 70.7 108.5 73.3L74.2 126.9C72.7 129.2 70.4 130.5 67.7 130.5Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M126.9 101C126.2 102.2 125 102.9 123.6 102.9H102.4C101 102.9 99.8 102.2 99.1 101C98.4 99.8 98.5 98.4 99.2 97.2L109.8 80.6C110.5 79.5 111.6 78.9 112.9 78.9C114.3 78.9 115.5 79.5 116.2 80.6L126.8 97.2C127.5 98.4 127.6 99.8 126.9 101Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_7_149" x1="-49.2079" y1="216.349" x2="176.468" y2="-32.122" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1F5B73" />
          <stop offset="0.1811" stop-color="#256775" />
          <stop offset="0.5114" stop-color="#35887A" />
          <stop offset="0.9509" stop-color="#4FBD82" />
          <stop offset="1" stop-color="#52C383" />
        </linearGradient>
      </defs>
    </svg>

  </div>
}

const SpotifyLogo = () => {
  return <div className="w-6 h-6 rounded-full">
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8_150)">
        <path fill="#1DB954" d="M18 0C8.06371 0 0 8.06371 0 18C0 27.9363 8.06371 36 18 36C27.9363 36 36 27.9363 36 18C36 8.06371 27.9363 0 18 0ZM25.3089 26.4847C25.004 26.4847 24.8153 26.3903 24.5323 26.2234C20.0032 23.4944 14.7339 23.3782 9.52984 24.4452C9.24677 24.5177 8.87661 24.6339 8.66613 24.6339C7.9621 24.6339 7.51935 24.075 7.51935 23.4871C7.51935 22.7395 7.9621 22.3839 8.50645 22.2677C14.4508 20.954 20.5258 21.0702 25.7081 24.1694C26.1508 24.4524 26.4121 24.7065 26.4121 25.3669C26.4121 26.0274 25.8968 26.4847 25.3089 26.4847ZM27.2613 21.7234C26.8839 21.7234 26.6298 21.5565 26.3685 21.4185C21.8323 18.7331 15.0677 17.6516 9.05081 19.2847C8.70242 19.379 8.51371 19.4734 8.1871 19.4734C7.41048 19.4734 6.77903 18.8419 6.77903 18.0653C6.77903 17.2887 7.15645 16.7734 7.90403 16.5629C9.92177 15.9968 11.9831 15.5758 15.0024 15.5758C19.7129 15.5758 24.2637 16.7444 27.8492 18.8782C28.4371 19.2266 28.6694 19.6766 28.6694 20.3081C28.6621 21.0919 28.0524 21.7234 27.2613 21.7234ZM29.5113 16.1927C29.1339 16.1927 28.9016 16.0984 28.575 15.9097C23.4073 12.825 14.1677 12.0847 8.1871 13.754C7.92581 13.8266 7.59919 13.9427 7.25081 13.9427C6.29274 13.9427 5.55968 13.1952 5.55968 12.2298C5.55968 11.2427 6.16935 10.6839 6.82258 10.4952C9.37742 9.74758 12.2371 9.39193 15.3508 9.39193C20.6492 9.39193 26.2016 10.4952 30.2589 12.8613C30.825 13.1879 31.1952 13.6379 31.1952 14.5016C31.1952 15.4887 30.3968 16.1927 29.5113 16.1927Z" />
      </g>
      <defs>
        <clipPath id="clip0_8_150">
          <rect width="36" height="36" fill="#1DB954" />
        </clipPath>
      </defs>
    </svg>
  </div>
}

const FacebookLogo = () => {
  return <div className="w-6 h-6 rounded full">
    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35 17.6065C35 7.88032 27.1673 0 17.5 0C7.83266 0 0 7.88032 0 17.6065C0 26.3941 6.3995 33.6781 14.7656 35V22.696H10.3201V17.6065H14.7656V13.7274C14.7656 9.31511 17.3765 6.87789 21.3754 6.87789C23.2905 6.87789 25.2931 7.2215 25.2931 7.2215V11.5521H23.0859C20.9125 11.5521 20.2344 12.9095 20.2344 14.3017V17.6065H25.0878L24.3116 22.696H20.2344V35C28.6005 33.6781 35 26.3941 35 17.6065Z" fill="#4267B2" />
    </svg>
  </div>

}

const BLANK_SYMBOL = '-';


export default function CampaignsTable({ campaigns, isLoading }) {

  const [filteredCampaigns, setFilteredCampaigns] = React.useState(campaigns);

  useEffect(() => {
    setFilteredCampaigns(campaigns);
  }, [campaigns]);

  return (
    <>
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-white0">Campaigns</h1>
          <p className="mt-2 text-sm text-white1">
            Currently there are {campaigns.length} campaigns running on {
              campaigns.map(campaign => campaign.platform + " (" + campaigns.filter(c => c.platform === campaign.platform).length + ")")
                .filter((value, index, array) => array.indexOf(value) === index).join(", ").replace(/,(?!.*,)/gmi, ' and')
            }.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <a href="campaigns/create">
            <button
              type="button"
              className="block rounded-md bg-green px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add campaign
            </button>
          </a>
        </div>
      </div>
      <div className="mt-6">
        {/* A filter bar with dropdown menu that lists available platforms */}
        <div className="relative inline-block text-left">
          <div>
            <label htmlFor="platforms" className="m-1 text-white0">Select a platform</label>
            <select className=" m-1 rounded-md bg-white px-3 py-1 text-center text-sm font-semibold text-black0 hover:text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green"
              onChange={(e) => {
                if (e.target.value === "All Platforms") {
                  setFilteredCampaigns(campaigns);
                } else {
                  setFilteredCampaigns(campaigns.filter(campaign => campaign.platform === e.target.value));
                }
              }}
            >
              <option defaultValue={true}>All Platforms</option>
              {
                campaigns.map(campaign => campaign.platform).filter((value, index, array) => array.indexOf(value) === index).map(platform => <option className="text-gray-700 hover:text-gray-900">{platform}</option>)
              }
            </select>
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className=" inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 bg-gray2 py-2 px-4 rounded">
            {
              isLoading && (
                <div className="inset-0 z-50 bg-opacity-50 flex items-center justify-center">
                  <div className="p-4 rounded-md">
                    <div className="flex items-center justify-center">
                      {/* <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green"></div> */}
                      <LoadingAnimation height="50px"/>
                    </div>
                    <div className="text-center text-white mt-2">Loading...</div>
                  </div>
                </div>
              )
            }
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white1 sm:pl-0">
                    Platform
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white1 sm:pl-0">
                    Name
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white1 sm:pl-0">
                    ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white1">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white1">
                    Impressions
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white1">
                    Channel
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white1">
                    Advertiser ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white1">
                    Total Budget
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white1">
                    Daily Budget
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white0">
                {filteredCampaigns.map((campaign) => (
                  <tr key={campaign.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white0 sm:pl-0 ">
                      {campaign.platform === "Pinterest" ? <PinterestLogo /> : (campaign.platform === "Spotify" ? <SpotifyLogo /> : (campaign.platform === "Facebook" ? <FacebookLogo /> : <ActiveAgentLogo />))}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white0 sm:pl-0 ">
                      {campaign.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-white0 w-2">{campaign.id ?? BLANK_SYMBOL}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-white0">{campaign.status ?? BLANK_SYMBOL}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-white0">{campaign.impressions ?? BLANK_SYMBOL}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-white0">{campaign.channelKind ?? BLANK_SYMBOL}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-white0">{campaign.advertiser ?? BLANK_SYMBOL}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-white0">{campaign.totalBudget ? ((campaign.totalBudget / 100) + "€") : BLANK_SYMBOL}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-white0">{campaign.dailyBudget ? ((campaign.dailyBudget / 100) + "€") : BLANK_SYMBOL}</td>
                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button class="bg-green hover:bg-green py-2 px-4 rounded ">
                        <a href="#" className=" text-white0 hover:text-white0 ">
                          Edit<span className="sr-only">, {campaign.name}</span>
                        </a></button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
