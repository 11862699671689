import React from "react";
import { useAuth } from "../../Hooks/useAuth";

const User = () => {
  const { user } = useAuth();
  return (
    <div>
      <h1>{user.email}</h1>
    </div>
  );
};

export default User;