import { n2api } from "./n2api";

const useUser = () => {
  const getUser = async (token) => {
    return n2api
      .get(`/auth/user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data.user;
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });
  };

  return { getUser };
};

export default useUser;