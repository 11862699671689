import axios from 'axios'
import { n2api } from './n2api';
import { useAuth } from '../Hooks/useAuth';


const usePinterestAuth = () => {
    const { token } = useAuth();

    const getAccessToken = (code, clientId, clientSecret) => {

        const redirectUri = 'http://localhost:3000/platforms/pinterest/auth/success/';

        // darf nicht mehr hardcoded 
        const authHeader = 'Basic MTQ4MDQ2NjpjZGMxYjlmNjIxYjMzMjg2NzRiMmZkYWVlMDE5MDg4YTNmMWRjZjNk'
        const requestBody = `grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}`


        return axios.post('https://api.pinterest.com/v5/oauth/token/', requestBody,
            {
                headers: {
                    'Authorization': authHeader,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 302) {
                    if (response.data.access_token) {
                        return {
                            success: true,
                            access_token: response.data.access_token,
                            data: response.data

                        }
                    } else {
                        return {
                            success: false,
                            response: response,
                            error: new Error('No access token found in the response')
                        }

                    }
                } else {
                    return {
                        success: false,
                        response: response,
                        error: new Error(response.statusText)
                    }
                }
            })

            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    response: {},
                    error: error
                }
            });
    }

    const storeAccessToken = (accessToken) => {
        const createCredentialDto = {
            accessToken: accessToken
        };

        // Make a POST request to the n2api backend to store the refresh token
        n2api.put(`/pinterest/credentials`, createCredentialDto, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                console.log('Access token stored successfully');
                // Handle the response as needed
            })
            .catch((error) => {
                console.error('Error storing access token:', error);
                // Handle the error
            });
    }


    return {
        getAccessToken,
        storeAccessToken
    }

}

export default usePinterestAuth;