import axios from 'axios'
import { n2api } from './n2api';
import { useAuth } from '../Hooks/useAuth';

const useGoogleAdsAuth = () => {
    
    const { token } = useAuth();
    
    
    //returns a JSON-file containing the refresh token
    const getRefreshToken = (code, client_id, client_secret) => {
        const requestBody = `code=${code}&client_id=${client_id}&client_secret=${client_secret}&redirect_uri=http://localhost:3000/platforms/googleads/auth/success&grant_type=authorization_code`;
        return axios.post('https://oauth2.googleapis.com/token', requestBody,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(response => {
            if (response.status === 200 || response.status === 201 || response.status === 302) {
                if (response.data.refresh_token) {
                    return {
                        success: true,
                        refresh_token: response.data.refresh_token,
                        data: response.data
                    }
                } else {
                    return {
                        success: false,
                        response: response,
                        error: new Error('No refresh token found in the response')
                    }
                    
                }
            } else {
                return {
                    success: false,
                    response: response,
                    error: new Error(response.statusText)
                }
            }
        })
        
        .catch(error => {
            console.log(error);
            return {
                success: false,
                response: {},
                error: error
            }
        });
        
        
    }
    
    const storeRefreshToken = (refreshToken) => {
        const createCredentialDto = {
            refreshToken: refreshToken
        };
        
        // Make a POST request to the n2api backend to store the refresh token
        n2api.put(`/googleads/credentials`,  createCredentialDto ,{
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            console.log('Refresh token stored successfully');
            // Handle the response as needed
        })
        .catch((error) => {
            console.error('Error storing refresh token:', error);
            // Handle the error
        });
        
        
    }
    return {
        getRefreshToken,
        storeRefreshToken
    }
}

export default useGoogleAdsAuth;