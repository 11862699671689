import React from "react";
export default function FacebookLogo({ className, inverted}) {
  return (
    <div className={className}><svg xmlns="http://www.w3.org/2000/svg" version="1.1"
      viewBox="0 0 14222 14222"
>
      <defs>
        <style type="text/css">
        </style>
      </defs>
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path fill={inverted? "#1977F3":"#FEFEFE"} d="M14222 7111c0,-3927 -3184,-7111 -7111,-7111 -3927,0 -7111,3184 -7111,7111 0,3549 2600,6491 6000,7025l0 -4969 -1806 0 0 -2056 1806 0 0 -1567c0,-1782 1062,-2767 2686,-2767 778,0 1592,139 1592,139l0 1750 -897 0c-883,0 -1159,548 -1159,1111l0 1334 1972 0 -315 2056 -1657 0 0 4969c3400,-533 6000,-3475 6000,-7025z" />
        <path fill={inverted? "#FEFEFE":"#1977F3"} d="M9879 9167l315 -2056 -1972 0 0 -1334c0,-562 275,-1111 1159,-1111l897 0 0 -1750c0,0 -814,-139 -1592,-139 -1624,0 -2686,984 -2686,2767l0 1567 -1806 0 0 2056 1806 0 0 4969c362,57 733,86 1111,86 378,0 749,-30 1111,-86l0 -4969 1657 0z" />
      </g>
    </svg></div>
  )
}
