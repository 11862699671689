import { useAuth } from '../Hooks/useAuth';
import { n2api } from './n2api';
import axios from 'axios';

const useLinkedInAuth = () => {
    const { token } = useAuth();

    const getAccessToken = (authorization_code) => {
        const redirect_uri = process.env.REACT_APP_LINKED_IN_REDIRECT_URI;
        return n2api.post(`/linkedin/auth`, {
            code: authorization_code,
            redirect_uri: redirect_uri
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    response: {},
                    error: error
                }
            });
    }

    const getAdAccountIds = (accessToken) => {

        return n2api.post(`/linkedin/auth/adAccountIds`, {
            accessToken: accessToken,
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    response: {},
                    error: error
                }
            });
    }

    const storeCredentials = (accessToken, adAccountIds) => {
        const createCredentialDto = {
            accessToken: accessToken,
            adAccountIds: adAccountIds
        };

        return n2api.put(`/linkedin/credentials`, createCredentialDto, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                if (
                    response.status == 200 ||
                    response.status == 201 ||
                    response.status == 302
                ) {
                    console.log('Credentials stored successfully');
                    return {
                        success: true,
                    }
                }
                else return {
                    success: false,
                    error: new Error('Error storing Credentials')
                }

            })
            .catch((error) => {
                console.error('Error storing Credentials:', error);
                return {
                    success: false,
                    error: error
                }
            });
    }




    return { getAccessToken, getAdAccountIds, storeCredentials }
}


export default useLinkedInAuth;
