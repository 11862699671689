import React from 'react';
import useGoogleAdsAuth from '../../Api/useGoogleAdsAuth';
import AuthResponse from '../../Components/AuthResponse/Index';



const GoogleAdsAuth = () => {
    
    const googleAdsAuth = useGoogleAdsAuth();
    const [response, setResponse] = React.useState('')
    const [status, setStatus] = React.useState(false)
    
    
    
    const client_id = ""
    const client_secret = ""
    
    
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchParams = new URLSearchParams(urlObj.search); 
    const code = searchParams.get("code");
    
    
    
    React.useEffect(() => {
        async function getRefreshToken() {
            const pfs = await googleAdsAuth.getRefreshToken(code, client_id, client_secret)
            setStatus(pfs.success)
            
            if(pfs.success){
                setResponse("Google Ads")
                googleAdsAuth.storeRefreshToken(`${pfs.refresh_token}`)
            }   else {
                setResponse(`${pfs.error}`)     
                console.log('no refresh token found')
            }
        }
        getRefreshToken();
        
    }, [])
    
    return (
        <>
        <AuthResponse response={response} status={status} />
        </>
        
        
        );
    }
    
    export default GoogleAdsAuth;