import { n2api } from './n2api';

const useAdSets = () => {

  const getRunningAdSets = (platformName, token) => {
    return n2api.get(`/${platformName.toLowerCase()}/adsets?status=RUNNING&childResources=AdSet&childResources=Campaign`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getAdSet = (platformName, adSetId, token) => {
    return n2api.get(`/${platformName}/adsets/${adSetId}&childResources=,`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  return {
    getRunningAdSets,
    getAdSet
  };
}

export default useAdSets;