export default function LinkedInConnectButton() {
    const client_id = '779jh00btlm8ye'
    return (
        <div className="-ml-px ">
            <a
                href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&redirect_uri=http://localhost:3000/platforms/linkedin/auth/success&client_id=779jh00btlm8ye&scope=r_ads_reporting%20r_organization_social%20rw_organization_admin%20w_member_social%20r_ads%20w_organization_social%20rw_ads%20r_basicprofile%20r_organization_admin%20r_1st_connections_size

    `}  >
                <button
                    className={`bg-green hover:bg-opacity-70 text-white0 font-semibold py-2 px-9 rounded-full`}>
                    <p>Connect to LinkedIn</p>
                </button>
            </a>
        </div>
    );
}