import { n2api } from "./n2api";

const useFacebookPages = () => {
  const apiVersion = process.env.REACT_APP_FACEBOOK_API_VERSION;
  const getPages = async (token) => {
    return n2api
      .get(`/facebook/raw/${apiVersion}/me/accounts`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data.responses.map(res => res.data).flat();
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });
  };

  return { getPages };
};

export default useFacebookPages;