import React from "react"
import useFacebookPages from "../../Api/useFacebookPages";
import { useAuth } from "../../Hooks/useAuth";

export default function FacebookPagesList() {

  const [pages, setPages] = React.useState([]);
  const {getPages} = useFacebookPages();
  const { token } = useAuth();

  React.useEffect(() => {
    getPages(token).then(pages => {
      setPages(pages);
    })
  }, [])

  return <div className="text-white my-8">
    <h1 className="text-lg py-4">Connected Facebook Pages</h1>
    <ul className="divide-y divide-gray-100">
      {pages.map(page => 
        <li className="flex border rounded max-w-md  border-gray2 justify-between gap-x-6 p-4 py-5" key={page.id}>
          <div className="flex min-w-0 gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={`https://api.dicebear.com/6.x/initials/svg?seed=${page.name}`} alt="" />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 ">
                {page.name}
              </p>
              <p className="mt-1 truncate text-xs leading-5 ">
                {page.id}
              </p>
            </div>
          </div>
          <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 ">
              {page.category}
            </p>
            {/* <p class="mt-1 text-xs leading-5 text-gray-500">Last seen <time datetime="2023-01-23T13:23Z">3h ago</time></p> */}
          </div>
        </li>)
      }
    </ul>
  </div>
}