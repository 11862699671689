import FacebookPagesList from "../../Components/FacebookPagesList/Index"

export default function PlatformInfoFactory({ platformName }) {
  switch(platformName.toLowerCase()) {
    case 'facebook':
      return <FacebookPagesList />
    default:
      return <></>
  }
}
