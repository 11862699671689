import React from 'react';
import usePlatforms from '../../Api/usePlatforms';
import PlatformsGridList from '../../Components/PlatformsGridList/Index';
import { useAuth } from '../../Hooks/useAuth';

const Platforms = () => {

  const platform = usePlatforms();
  const { token } = useAuth();
  const [platforms, setPlatforms] = React.useState([]);

  React.useEffect(() => {
    async function getPlatforms() {
      const pfs = await platform.getPlatforms(token);
      console.log(pfs);
      setPlatforms(pfs);
    }
    getPlatforms();
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold text-white0 my-6">Platforms</h1>
      <PlatformsGridList items={platforms} baseUrl={'/platforms'} />
    </div>
  );
}

export default Platforms;