import React from 'react';
import AuthResponse from '../../Components/AuthResponse/Index';
import useLinkedInAuth from '../../Api/useLinkedInAuth';

const LinkedInAuth = () => {

    const linkedInAuth = useLinkedInAuth();
    const [response, setResponse] = React.useState('')
    const [status, setStatus] = React.useState(false)

    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchParams = new URLSearchParams(urlObj.search);
    const authorization_code = searchParams.get("code");
    const error_reason = searchParams.get("error_reason");
    const error = searchParams.get("error");

    React.useEffect(() => {
        async function getCredentials() {
            if (!authorization_code) {
                setResponse(`No authorization code received${error_reason ? '. Reason: ' + error_reason : ''}`);
                return;
            }
            if (error) {
                setResponse(error);
                return;
            }
            const pfs = await linkedInAuth.getAccessToken(authorization_code)
            const adAccountIds = await linkedInAuth.getAdAccountIds(pfs.access_token)

            if (pfs.success && adAccountIds.success) {
                const response = await linkedInAuth.storeCredentials(pfs.access_token, adAccountIds.adAccountIds);
                if (response.success) {
                    setResponse("LinkedIn");
                    setStatus(true);
                } else setResponse(`${response.error}`);

            } else {
                setResponse(`${pfs.error}`);
                console.log('no access token')
            }

        }
        getCredentials();

    }, [])
    return (
        <>
            <AuthResponse response={response} status={status} />
        </>


    );
}

export default LinkedInAuth;