import React from "react";
export default function GoogleConnectButton() {
  const google_redirect_url = `http://localhost:3000/platforms/googleads/auth/success`
  const google_client_id = "77135699377-brc0bma3965k9eot9gb1ap6t739r5cvm.apps.googleusercontent.com"

  return (
    <div className="-ml-px ">
        <a
          href={`https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/adwords&access_type=offline&response_type=code&client_id=${google_client_id}&redirect_uri=${google_redirect_url}`}
          className="relative inline w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
        >
          <button
            className={`bg-green hover:bg-opacity-70 text-white0 font-semibold py-2 px-9 rounded-full`}>
            <p>Connect to Google Ads</p>
          </button>
        </a>
      </div>
  );
}