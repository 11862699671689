import React from "react";
import ActiveAgentLogo from "../PlatformLogos/ActiveAgentLogo";
export default function ActiveAgentConnectButton() {

  return (
    <div className="-ml-px ">
        <a
          href={`/activeagent/credentials`}
        >
          <button
            className={`bg-green hover:bg-opacity-70 text-white0 font-semibold flex items-center py-2 px-9 rounded`}>
            <ActiveAgentLogo className="w-6 h-6 mr-2" />
            <p>Connect ActiveAgent</p>
          </button>
        </a>
      </div>
  );
}