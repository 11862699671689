import React, { useContext } from "react";
import AuthLayout from "./AuthLayout";
 import { useApiAuth } from "../../Api/useAuth";
import useUser from "../../Api/useUser";
import { useAuth } from "../../Hooks/useAuth";

const Login = () => {
  const { login } = useAuth();
  const { apiLogin } = useApiAuth();
  const { getUser } = useUser();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);

  const handleLogin = () => {
    setLoggingIn(true);
    setLoginFailed(false);
    apiLogin(email, password).then((response) => {
      if (response?.access_token) {
        getUser(response.access_token).then((user) => {
          login({ user: user, token: response.access_token });
        });
      } else {
        console.log("Login Failed");
        console.log(response);
        setLoginFailed(true);
        setTimeout(() => setLoginFailed(false), 3000);
        setLoggingIn(false);
      }
    })
  };

  return (
    <AuthLayout loggingIn={loggingIn} loginFailed={loginFailed} onLogin={handleLogin} setEmail={setEmail} setPassword={setPassword}>
    </AuthLayout>
  );
};

export default Login;
