import React from 'react';
import useFacebookAuth from '../../Api/useFacebookAuth';
import AuthResponse from '../../Components/AuthResponse/Index';



const FacebookAuth = () => {

    const facebookAuth = useFacebookAuth();
    const [response, setResponse] = React.useState('')
    const [status, setStatus] = React.useState(false)

    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchParams = new URLSearchParams(urlObj.search);
    const authorization_code = searchParams.get("code");
    const error_reason = searchParams.get("error_reason");
    const error = searchParams.get("error");

    React.useEffect(() => {
        async function getCredentials() {
            if(!authorization_code) {
                setResponse(`No authorization code received${error_reason? '. Reason: ' + error_reason: ''}`);
                return;
            }
            if(error) {
                setResponse(error);
                return;
            }
            const pfs = await facebookAuth.getAccessToken(authorization_code)
            if(!pfs.success) {
                setStatus(pfs.success)
                setResponse(pfs.error);
                return;
            }
            const managerData = await facebookAuth.getAdAccountId(pfs.access_token)
            setStatus(pfs.success && managerData.success)
            console.log(pfs)
            console.log(managerData)

            if (pfs.success && managerData.success) {
                setResponse("Facebook");
                facebookAuth.storeCredentials(`${pfs.access_token}`, managerData.adAccountIds);

            } else {
                pfs.error && setResponse(`${pfs.error}`);
                managerData.error && setResponse(`${managerData.error}`);
                console.log('no access token')
            }

        }
        getCredentials();

    }, [])

    return (
        <>
            <AuthResponse response={response} status={status} />
        </>


    );
}

export default FacebookAuth;